import React, { useEffect, useState } from 'react'

import classNames from 'classnames'

import { ColorScheme } from 'ui/styling/baseVariables/colors/colorPalette'
import { colorSchemeVar } from 'ui/styling/GlobalStyles.css'
import { Breakpoint, breakpoints } from 'ui/styling/helpers/breakpoints'
import { adminTheme, darkTheme, lightTheme, onboardingTheme } from 'ui/styling/Theme.css'

import makeAdminThemeVariables from './admin'
import makeDefaultThemeVariables from './default'

type ThemeName = 'default' | 'dark' | 'admin' | 'onboarding'

type ThemeProviderRef = HTMLDivElement

type ThemeProviderProps = React.ComponentPropsWithoutRef<'div'> & {
    theme?: ThemeName
}

export const ThemeProvider = React.forwardRef<ThemeProviderRef, ThemeProviderProps>(
    ({ children, className, theme = 'default', ...props }, ref) => {
        const themeClassName = mapThemeNameToClassName(theme)
        const rawVariables = mapThemeNameToRawVariables(theme)

        const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint>('mobile')

        useEffect(() => {
            const handleResize = () => {
                const width = window.innerWidth
                const breakpoint = Object.entries(breakpoints).find(
                    ([_, { start, end }]) => width >= start && (end === undefined || width < end)
                )
                if (breakpoint) {
                    setCurrentBreakpoint(breakpoint[0] as Breakpoint)
                }
            }

            handleResize()
            window.addEventListener('resize', handleResize)
            return () => window.removeEventListener('resize', handleResize)
        }, [])

        const colorSchemeVariableName = colorSchemeVar.replace('var(', '').replace(')', '')

        const colorScheme =
            (window
                .getComputedStyle(document.querySelector(':root')!)
                .getPropertyValue(colorSchemeVariableName)
                .trim() as ColorScheme) || 'Indigo'

        return (
            <ThemeContext.Provider
                value={{
                    theme,
                    themeClassName,
                    themeRawVariables: rawVariables,
                    breakpoint: currentBreakpoint,
                    colorScheme,
                }}
            >
                <div {...props} className={classNames(className, themeClassName)} ref={ref}>
                    {children}
                </div>
            </ThemeContext.Provider>
        )
    }
)

function mapThemeNameToClassName(themeName: ThemeName): string | undefined {
    switch (themeName) {
        case 'dark':
            return darkTheme
        case 'admin':
            return adminTheme
        case 'onboarding':
            return onboardingTheme
        default:
            return lightTheme
    }
}

function mapThemeNameToRawVariables(themeName: ThemeName) {
    switch (themeName) {
        case 'admin':
            return makeAdminThemeVariables()
        case 'onboarding':
            return makeAdminThemeVariables()
        default:
            return makeDefaultThemeVariables()
    }
}

type ThemeContextValue = {
    theme: ThemeName
    themeClassName: string | undefined
    themeRawVariables: ReturnType<typeof mapThemeNameToRawVariables>
    breakpoint: Breakpoint
    colorScheme: ColorScheme
}

const defaultContext: ThemeContextValue = {
    theme: 'default',
    themeClassName: undefined,
    themeRawVariables: mapThemeNameToRawVariables('default'),
    breakpoint: 'mobile',
    colorScheme: 'Indigo',
}

const ThemeContext = React.createContext<ThemeContextValue>(defaultContext)

export function useTheme(): ThemeContextValue {
    return React.useContext(ThemeContext)
}
