/* Code Quality: Not audited */

import { css } from '@emotion/react'

import Consolas from 'v2/ui/fonts/Consolas.woff'
import ProximaNova from 'v2/ui/fonts/ProximaNova-Regular.woff'
import ProximaNova2 from 'v2/ui/fonts/ProximaNova-Regular.woff2'
import ProximaNovaSBold from 'v2/ui/fonts/ProximaNova-Semibold.woff'
import ProximaNovaSBold2 from 'v2/ui/fonts/ProximaNova-Semibold.woff2'

// These are used in one of the CSS files from index.html, but esbuild
// won't pick these up automatically, so we need to import them here.
// eslint-disable-next-line unused-imports/no-unused-imports
import avenirNext from '/src/assets/fonts/avenir-next.woff2'
// eslint-disable-next-line unused-imports/no-unused-imports
import avenirNextBold from '/src/assets/fonts/avenir-next-bold.woff'
// eslint-disable-next-line unused-imports/no-unused-imports
import avenirNextDemi from '/src/assets/fonts/avenir-next-demi.woff2'
import eina01bold from '/src/assets/fonts/Eina01-Bold.ttf'
import eina01boldWoff from '/src/assets/fonts/Eina01-Bold.woff'
import eina01boldWoffTwo from '/src/assets/fonts/Eina01-Bold.woff2'
import eina01light from '/src/assets/fonts/Eina01-Light.ttf'
import eina01lightWoff from '/src/assets/fonts/Eina01-Light.woff'
import eina01lightWoffTwo from '/src/assets/fonts/Eina01-Light.woff2'
import eina01regular from '/src/assets/fonts/Eina01-Regular.ttf'
import eina01regularWoff from '/src/assets/fonts/Eina01-Regular.woff'
import eina01regularWoffTwo from '/src/assets/fonts/Eina01-Regular.woff2'
import eina01semiBold from '/src/assets/fonts/Eina01-SemiBold.ttf'
import eina01semiBoldWoff from '/src/assets/fonts/Eina01-SemiBold.woff'
import eina01semiBoldWoffTwo from '/src/assets/fonts/Eina01-SemiBold.woff2'

export const GlobalStyleV2 = css`
    html,
    body {
        height: 100%;
        overflow: hidden;
    }

    p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }

    @font-face {
        font-family: 'Eina01';
        font-display: fallback;
        src:
            url(${eina01lightWoffTwo}) format('woff2'),
            url(${eina01lightWoff}) format('woff'),
            url(${eina01light}) format('truetype');
        font-weight: 300;
    }

    @font-face {
        font-family: 'Eina01';
        font-display: fallback;
        src:
            url(${eina01regularWoffTwo}) format('woff2'),
            url(${eina01regularWoff}) format('woff'),
            url(${eina01regular}) format('truetype');
        font-weight: 400;
    }

    @font-face {
        font-family: 'Eina01';
        font-display: fallback;
        src:
            url(${eina01semiBoldWoffTwo}) format('woff2'),
            url(${eina01semiBoldWoff}) format('woff'),
            url(${eina01semiBold}) format('truetype');
        font-weight: 600;
    }

    @font-face {
        font-family: 'Eina01';
        font-display: fallback;
        src:
            url(${eina01boldWoffTwo}) format('woff2'),
            url(${eina01boldWoff}) format('woff'),
            url(${eina01bold}) format('truetype');
        font-weight: 700;
    }

    @font-face {
        font-family: 'Proxima Nova';
        src:
            url(${ProximaNova2}) format('woff2'),
            url(${ProximaNova}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Proxima Nova';
        src:
            url(${ProximaNovaSBold}) format('woff2'),
            url(${ProximaNovaSBold2}) format('woff');
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: 'Consolas';
        src: url(${Consolas}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    a {
        text-decoration: none;
        color: #2980b9;
    }

    html,
    body,
    textarea,
    input {
        font-family: 'Proxima Nova', sans-serif;
        line-height: 1.2;
    }

    #app {
        height: 100%;
    }

    .container-block > .block {
        flex-grow: 1;
        flex-wrap: wrap;
    }

    *,
    *::before,
    *::after {
    }

    // hacky fix to work around stream.io's components odd positioning

    .raf-icon-badge {
        margin-right: -7px;

        @media (max-width: 768px) {
            margin-right: -3px;
        }
    }
`

export default GlobalStyleV2
