import React from 'react'
import { useForm } from 'react-hook-form'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Tag } from 'ui/components/Tag'
import { Body } from 'ui/components/Text'
import { Textarea } from 'ui/components/Textarea'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { RequirementSchema, RequirementSchemaType } from './bundles/zodSchema'
import { getChatCompletion } from './chatUtils/chatApi'
import { zodToOpenAiResponseFormat } from './chatUtils/zodUtils'
import { useAiAppBuilder } from './AiAppBuilderContext'

type FormDataType = {
    description: string
}

export function Step1Describe() {
    const {
        description,
        clearbitContext,
        setDescription,
        setRequirements,
        generationFailed,
        setGenerationFailed,
        setCurrentStep,
    } = useAiAppBuilder()
    const buttonSize = useResponsiveValue({ mobile: 'm', tabletLarge: 'l' }) as 'm' | 'l'

    const {
        register,
        formState: { errors, isSubmitting },
        setValue,
        handleSubmit,
    } = useForm<FormDataType>({
        defaultValues: { description },
    })

    async function generateRequirements({ description }: FormDataType) {
        if (generationFailed) {
            setRequirements([])
            setCurrentStep('requirements')
            return
        }
        console.log('description', description)
        try {
            const response = await getChatCompletion(
                [
                    {
                        role: 'system',
                        content:
                            'You are an expert database architect. Generate the key entities that require dedicated tables that will meet the requirements of the user. Only generate the most important entities, probably less than 5. Use only 6-10 words to describe each entity. Assume general platform functionality (logging in, users etc) is already taken care of. ' +
                            clearbitContext,
                    },
                    { role: 'user', content: description },
                ],
                undefined,
                zodToOpenAiResponseFormat(RequirementSchema, 'RequirementsSchema')
            )

            const result = JSON.parse(response.content ?? '[]') as RequirementSchemaType
            setRequirements(result.requirements)
            setDescription(description)
            setCurrentStep('requirements')
        } catch (ex) {
            console.error(ex)
            setGenerationFailed(true)
        }
    }

    return (
        <Box as="form" flex column gap="5xl" onSubmit={handleSubmit(generateRequirements)}>
            <Body size="l">
                Tell us about your use-case so that our AI builder can create your app for you.
            </Body>

            <Textarea
                {...register('description', {
                    required: { value: true, message: 'Please describe your use-case' },
                })}
                isError={errors?.description}
                helperText={errors?.description?.message}
                autoFocus
                placeholder="e.g., An app to manage my dog grooming business"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                size="l"
            />

            <Box flex column gap="m">
                <Body size="m" color="textWeaker">
                    Or try one of these examples
                </Body>
                <Box gap="s" flex wrap>
                    <Tag
                        type="outline"
                        size="s"
                        onClick={() =>
                            setValue('description', 'A custom CRM to help me track my sales')
                        }
                    >
                        Sales CRM
                    </Tag>
                    <Tag
                        type="outline"
                        size="s"
                        onClick={() =>
                            setValue(
                                'description',
                                'A way to track maintenance requests from my tenants'
                            )
                        }
                    >
                        Work tracker
                    </Tag>
                    <Tag
                        type="outline"
                        size="s"
                        onClick={() =>
                            setValue(
                                'description',
                                'A self-serve portal for my customers to see their order history'
                            )
                        }
                    >
                        Customer portal
                    </Tag>
                    <Tag
                        type="outline"
                        size="s"
                        onClick={() =>
                            setValue(
                                'description',
                                'A way to keep track the tasks in my construction project'
                            )
                        }
                    >
                        Project management
                    </Tag>
                    <Tag
                        type="outline"
                        size="s"
                        onClick={() =>
                            setValue(
                                'description',
                                'An order management system for our custom bike orders'
                            )
                        }
                    >
                        Order tracker
                    </Tag>
                    <Tag
                        type="outline"
                        size="s"
                        onClick={() =>
                            setValue(
                                'description',
                                'A secure dashboard for our partners to submit work requests'
                            )
                        }
                    >
                        Partner dashboard
                    </Tag>
                </Box>
            </Box>
            <Button
                size={buttonSize}
                type="submit"
                alignSelf="stretch"
                disabled={generationFailed}
                isLoading={isSubmitting}
            >
                Continue
            </Button>
        </Box>
    )
}
