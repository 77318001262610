import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import { getUrl, Urls } from 'app/UrlService'
import { useAccounts } from 'data/hooks/accounts'
import { AiAppBuilderProvider, useAiAppBuilder } from 'features/AiPlayground/AiAppBuilderContext'
import { Bundle, bundleBuilder } from 'features/AiPlayground/bundles/bundleBuilder'
import { RequirementsStep } from 'features/AiPlayground/RequirementsStep'
import { Step1Describe } from 'features/AiPlayground/Step1Describe'
import { OnboardingFrame } from 'features/Onboarding/OnboardingFrame'
import { OnboardingProgress } from 'features/Onboarding/OnboardingProgress'

import { LoadingSplash } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { STEP_COUNT_PRIOR_TO_BUILDER } from './common'
import { initializeAccount } from './onboardingApi'
import { OnboardingAppPreview } from './OnboardingAppPreview'
import { OnboardingMobileBlock } from './OnboardingMobileBlock'

const SAMPLE_APP_BUNDLE_URL = 'https://resources.go.stackerhq.com/templates/default_sample_app.json'

export function OnboardingStartPage() {
    const { data: accounts, isLoading } = useAccounts()
    const showMobileBlock = useResponsiveValue({ mobile: true, desktop: false })
    const history = useHistory()

    async function handleInstallDefaultBundle() {
        console.log('# Importing default app bundle')
        const { account, stack } = await initializeAccount({ bundleUrl: SAMPLE_APP_BUNDLE_URL })
        history.replace(getUrl(Urls.Home, stack, account))
    }

    if (isLoading) {
        return <LoadingSplash />
    }
    if (accounts?.length ?? 0 > 0) {
        return <Redirect to={Urls.Root} />
    }
    if (showMobileBlock) {
        return <OnboardingMobileBlock installDefaultBundle={handleInstallDefaultBundle} />
    }
    return (
        <AiAppBuilderProvider>
            <Contents installDefaultBundle={handleInstallDefaultBundle} />
        </AiAppBuilderProvider>
    )
}

function Contents({ installDefaultBundle }: { installDefaultBundle: () => Promise<void> }) {
    const { currentStep, bundle, setCurrentStep } = useAiAppBuilder()

    const history = useHistory()

    async function handleFinish(bundle: Bundle) {
        const result = bundleBuilder(bundle)
        console.log('# Importing bundle', { simple: bundle, full: result })
        const { account, stack } = await initializeAccount({ bundle: result })
        history.replace(getUrl(Urls.Home, stack, account))
    }

    const headline =
        currentStep === 'describe' ? 'What are you building?' : 'What are you trying to track?'

    function goBack() {
        if (currentStep === 'describe') {
            sessionStorage.removeItem('onboardingCompanyName')
            history.push(Urls.OnboardingWhatAreYouBuilding)
        } else {
            setCurrentStep('describe')
        }
    }
    return (
        <OnboardingFrame
            title="Get started with Stacker"
            headline={headline}
            previewContents={<OnboardingAppPreview installDefaultBundle={installDefaultBundle} />}
            footerContents={<Footer />}
            onGoBack={goBack}
        >
            <Box grow>
                <Box flex column gap="l">
                    {currentStep === 'describe' && <Step1Describe />}
                    {currentStep === 'requirements' && (
                        <RequirementsStep onMoveNext={() => handleFinish(bundle!)} />
                    )}
                </Box>
            </Box>
        </OnboardingFrame>
    )
}

function Footer() {
    const { currentStep } = useAiAppBuilder()
    const stepNumber = currentStep === 'describe' ? 1 : 2
    return (
        <OnboardingProgress
            steps={STEP_COUNT_PRIOR_TO_BUILDER + 2}
            currentStep={STEP_COUNT_PRIOR_TO_BUILDER + stepNumber}
        />
    )
}
