import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { Urls } from 'app/UrlService'
import publicAsset from 'utils/publicAsset'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Input } from 'ui/components/Input'
import { Body } from 'ui/components/Text'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { OnboardingFrame } from './OnboardingFrame'
import { OnboardingProgress } from './OnboardingProgress'

import {
    OnboardingButtonHeadingStyle,
    OnboardingButtonImageStyle,
    OnboardingButtonSelectedStyle,
    OnboardingButtonStyle,
    OnboardingButtonSubheadingStyle,
    OnboardingButtonTextContainerStyle,
    OnboardingInternalContainerStyle,
} from './OnboardingButtonStyles.css'

type Usecase = 'project' | 'startup' | 'business'
export function OnboardingWhatAreYouBuilding() {
    const [usecase, setUsecase] = useState<Usecase | undefined>()
    const history = useHistory()
    const buttonSize = useResponsiveValue({ mobile: 'm', tabletLarge: 'l' }) as 'm' | 'l'

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: '',
        },
    })

    const onSubmit = ({ name }) => {
        sessionStorage.setItem('onboardingCompanyName', name)
        history.push(Urls.OnboardingStart)
    }

    const buttonsContext = [
        {
            image: publicAsset('/static/media/stickynote.svg'),
            heading: 'Personal Project',
            subheading: 'eg. personal apps, clubs and societies',
            useCase: 'project' as Usecase,
        },
        {
            image: publicAsset('/static/media/lamp-charge.svg'),
            heading: 'Creating a new startup',
            subheading: 'e.g. a new online marketplace, a new software product',
            useCase: 'startup' as Usecase,
        },
        {
            image: publicAsset('/static/media/briefcase.svg'),
            heading: 'Business app for my work',
            subheading: 'e.g. internal database, CRM, customer portal, workflow tracker',
            useCase: 'business' as Usecase,
        },
    ]

    return (
        <OnboardingFrame
            headline="What are you building?"
            title="Get started with Stacker"
            footerContents={<OnboardingProgress steps={3} currentStep={1} />}
            previewContents={
                <Box flex column center gap="4xl">
                    <Body textAlign="center" weight="medium" size="l">
                        High-performing teams at <br />
                        companies worldwide build with Stacker
                    </Body>
                    <img
                        src={publicAsset('/static/media/client-logo-strip.png')}
                        alt="Client logos"
                    />
                </Box>
            }
        >
            <Box as="form" onSubmit={handleSubmit(onSubmit)} flex column gap="5xl">
                <Body size="l">
                    Select the option that best describes what you&apos;re building
                </Body>
                <Box flex column gap="m">
                    {buttonsContext.map((button, index) => (
                        <Button
                            key={index}
                            className={
                                usecase === button.useCase
                                    ? OnboardingButtonSelectedStyle
                                    : OnboardingButtonStyle
                            }
                            onClick={() => setUsecase(button.useCase)}
                        >
                            <Box className={OnboardingInternalContainerStyle}>
                                <Box className={OnboardingButtonImageStyle}>
                                    <img src={button.image} alt={button.heading} />
                                </Box>
                                <Box className={OnboardingButtonTextContainerStyle}>
                                    <Box className={OnboardingButtonHeadingStyle}>
                                        {button.heading}
                                    </Box>
                                    {button.subheading && (
                                        <Box className={OnboardingButtonSubheadingStyle}>
                                            {button.subheading}
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Button>
                    ))}
                </Box>
                {usecase && (
                    <Input
                        autoFocus
                        {...register('name', { required: true })}
                        isError={!!errors.name}
                        helperText={
                            errors.name
                                ? usecase === 'project'
                                    ? 'Please enter a project name'
                                    : 'Please enter a company name'
                                : ''
                        }
                        label={usecase === 'project' ? 'Project name' : 'Company name'}
                    />
                )}
                <Button size={buttonSize} type="submit" alignSelf="stretch">
                    Continue
                </Button>
            </Box>
        </OnboardingFrame>
    )
}
