import React, { createContext, ReactNode, useContext, useState } from 'react'

import { Bundle } from './bundles/bundleBuilder'

type AiAppBuilderContextType = {
    clearbitContext: string
    bundle: Bundle | null
    generationFailed: boolean
    description: string
    requirements: string[]
    progress: { progress: number; status: string }
    isGenerating: boolean
    currentStep: 'describe' | 'requirements'
    setBundle: (bundle: Bundle | null) => void
    setGenerationFailed: (failed: boolean) => void
    setDescription: (description: string) => void
    setRequirements: (requirements: string[]) => void
    setProgress: (progress: { progress: number; status: string }) => void
    setIsGenerating: (isGenerating: boolean) => void
    setCurrentStep: (step: 'describe' | 'requirements') => void
}

const AiAppBuilderContext = createContext<AiAppBuilderContextType | undefined>(undefined)

export function AiAppBuilderProvider({ children }: { children: ReactNode }) {
    const [clearbitContext] = useState<string>('')
    const [bundle, setBundle] = useState<Bundle | null>(null)
    const [generationFailed, setGenerationFailed] = useState<boolean>(false)
    const [description, setDescription] = useState<string>('')
    const [requirements, setRequirements] = useState<string[]>([])
    const [progress, setProgress] = useState<{ progress: number; status: string }>({
        progress: 0,
        status: '',
    })
    const [isGenerating, setIsGenerating] = useState<boolean>(false)
    const [currentStep, setCurrentStep] = useState<'describe' | 'requirements'>('describe')

    const value = {
        clearbitContext,
        bundle,
        generationFailed,
        description,
        requirements,
        progress,
        isGenerating,
        currentStep,
        setBundle,
        setGenerationFailed,
        setDescription,
        setRequirements,
        setProgress,
        setIsGenerating,
        setCurrentStep,
    }

    return <AiAppBuilderContext.Provider value={value}>{children}</AiAppBuilderContext.Provider>
}

export function useAiAppBuilder() {
    const context = useContext(AiAppBuilderContext)
    if (context === undefined) {
        throw new Error('useAiAppBuilder must be used within an AiAppBuilderProvider')
    }
    return context
}
