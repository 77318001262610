import { useMemo } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { useListViewContext } from 'features/views/ListView/ListViewContext'
import { getDefaultColumnAlign } from 'features/views/ListView/TableView/columnAlign'
import { setColumnWidths } from 'features/views/ListView/TableView/columnWidth'
import { TableViewColumn } from 'features/views/ListView/TableView/types'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type UseColumnsFromViewProps = {
    isRecordActionsColumnPinned: boolean
    hasResizableColumns: boolean
}

export function useColumnsFromView({
    isRecordActionsColumnPinned,
    hasResizableColumns,
}: UseColumnsFromViewProps): TableViewColumn[] {
    const { view, visibleFields } = useListViewContext()

    const shouldPinPrimaryColumn = view.options.tablePinPrimaryColumn ?? true

    const columns = useMemo(() => {
        const columnsByFieldSid = view.options.columns.reduce(
            (acc, column) => acc.set(column.fieldId, column),
            new Map<string, ListViewColumnConfig>()
        )

        let tableViewColumns = visibleFields.reduce((acc, field) => {
            const column = columnsByFieldSid.get(field._sid)

            let title = column?.label ?? field.label
            if (column?.hideLabel) {
                title = ''
            }

            const fieldIcon = getFieldIcon(field)

            const computedColumn: TableViewColumn = {
                id: field._sid,
                title,
                field,
                isPrimary: false,
                isPinned: false,
                supportsSort: true,
                align: 'left',
                rawOptions: column ?? {},
                minWidth: 0,
                icon: fieldIcon,
                isResizable: hasResizableColumns,
            }
            computedColumn.align = getDefaultColumnAlign(computedColumn)

            if (column?.calculationType) {
                computedColumn.calculation = {
                    type: column.calculationType,
                }
            }

            acc.push(computedColumn)

            return acc
        }, [] as TableViewColumn[])

        if (tableViewColumns.length > 0) {
            // The first column is always pinned.
            tableViewColumns[0].isPinned = shouldPinPrimaryColumn
            tableViewColumns[0].isPrimary = true

            // If the record actions column is pinned, the last column is not resizable.
            if (isRecordActionsColumnPinned) {
                tableViewColumns[tableViewColumns.length - 1].isResizable = false
            }
        }

        // Calculate column sizes separately to take into account the final column order.
        tableViewColumns = setColumnWidths(tableViewColumns)

        return tableViewColumns
    }, [
        view.options.columns,
        visibleFields,
        hasResizableColumns,
        shouldPinPrimaryColumn,
        isRecordActionsColumnPinned,
    ])

    return useDeepEqualsMemoValue(columns)
}
