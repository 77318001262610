import React, { useCallback, useEffect, useRef, useState } from 'react'

import shortid from 'shortid'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Input } from 'ui/components/Input'
import { Tag } from 'ui/components/Tag'
import { Body } from 'ui/components/Text'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { generateBasicSchema } from './aiPrompts/generateBasicShema'
import { generateBasicRecords, generateRecords } from './aiPrompts/generateRecords'
import { generateTableNames } from './aiPrompts/generateTableNames'
import { Bundle } from './bundles/bundleBuilder'
import { useAiAppBuilder } from './AiAppBuilderContext'

export function RequirementsStep({ onMoveNext }: { onMoveNext: () => Promise<void> }) {
    const {
        requirements,
        setRequirements,
        setBundle,
        setProgress,
        generationFailed,
        setGenerationFailed,
        isGenerating,
        setIsGenerating,
    } = useAiAppBuilder()

    const buttonSize = useResponsiveValue({ mobile: 'm', tabletLarge: 'l' }) as 'm' | 'l'
    const requirementsRef = useRef(requirements)
    requirementsRef.current = requirements
    const [newRequirement, setNewRequirement] = useState('')
    const [showingAddRequirement, setShowingAddRequirement] = useState(false)
    const currentPreviewRequestId = useRef<string>('')
    const bundle = useRef<Bundle | null>(null)
    const isMounted = useRef(false)

    useEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
        }
    }, [])

    const doGenerateRecords = useCallback(
        async function (bundle: Bundle) {
            const requestId = currentPreviewRequestId.current

            setProgress({
                progress: 50,
                status: `preparing sample records...`,
            })
            // function updateProgressForObject(objectIndex: number) {
            //     if (objectIndex >= bundle.apps[0].objects.length) {
            //         return
            //     }
            //     // if a new request has started, then just bail
            //     if (requestId !== currentPreviewRequestId.current) {
            //         return
            //     }

            //     timeout = setTimeout(() => updateProgressForObject(objectIndex + 1), 2000)
            // }
            // let timeout = setTimeout(() => updateProgressForObject(0), 2000)

            let newBundle = await generateBasicRecords(bundle, currentPreviewRequestId.current)
            // if a new request has started, then just bail
            if (requestId !== currentPreviewRequestId.current || !isMounted.current) {
                return
            }

            setBundle(newBundle)
            let objectIndex = 0
            for (const obj of newBundle.apps[0].objects) {
                setProgress({
                    progress: 60 + (30 * (objectIndex + 1)) / bundle.apps[0].objects.length,
                    status: `generating sample records for ${bundle.apps[0].objects[objectIndex].name}...`,
                })
                newBundle = await generateRecords(
                    newBundle,
                    obj.id,
                    currentPreviewRequestId.current
                )

                setBundle(newBundle)
                objectIndex++
            }
            setIsGenerating(false)
        },
        [setBundle, setIsGenerating, setProgress]
    )

    const generateSchema = useCallback(
        async function generateSchema(bundle: Bundle) {
            const requestId = currentPreviewRequestId.current

            setProgress({
                progress: 10,
                status: `preparing table for ${bundle.apps[0].objects[0].name}...`,
            })
            function updateProgressForObject(objectIndex: number) {
                if (objectIndex >= bundle.apps[0].objects.length) {
                    return
                }

                // if a new request has started, then just bail
                if (requestId !== currentPreviewRequestId.current || !isMounted.current) {
                    return
                }

                setProgress({
                    progress: 10 + (40 * (objectIndex + 1)) / bundle.apps[0].objects.length,
                    status: `preparing table for ${bundle.apps[0].objects[objectIndex].name}...`,
                })
                timeout = setTimeout(() => updateProgressForObject(objectIndex + 1), 2000)
            }
            let timeout = setTimeout(() => updateProgressForObject(0), 2000)

            const newBundle = await generateBasicSchema(bundle, currentPreviewRequestId.current)

            // if a new request has started, then just bail
            if (requestId !== currentPreviewRequestId.current || !isMounted.current) {
                return
            }

            clearTimeout(timeout)
            setBundle(newBundle)

            await doGenerateRecords(newBundle)
            // await doGenerateRecords(newBundle)
            // await doGenerateRecords(newBundle)

            setBundle(newBundle)
        },
        [doGenerateRecords, setBundle, setProgress]
    )

    const updateSchema = useCallback(
        async function () {
            setIsGenerating(true)

            const requestId = shortid.generate()
            currentPreviewRequestId.current = requestId
            try {
                setProgress({ progress: 0, status: 'analyzing requirements...' })
                const newBundle = await generateTableNames(requirementsRef.current)

                // if a new request has started, then just bail
                if (requestId !== currentPreviewRequestId.current || !isMounted.current) {
                    return
                }

                setBundle(newBundle)
                bundle.current = newBundle
                await generateSchema(newBundle)
            } catch (e) {
                if (requestId === currentPreviewRequestId.current) {
                    console.error(e)
                    setGenerationFailed(true)
                }
            }
        },
        [setIsGenerating, setProgress, setBundle, generateSchema, setGenerationFailed]
    )

    useEffect(() => {
        updateSchema()
    }, [updateSchema])

    function removeRequirement(requirement: string) {
        setRequirements(requirements.filter((r) => r !== requirement))
        setTimeout(updateSchema, 100)
    }

    function saveNewItem() {
        setRequirements([...requirements, newRequirement])
        setNewRequirement('')
        setShowingAddRequirement(false)
        setTimeout(updateSchema, 100)
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            saveNewItem()
        } else if (e.key === 'Escape') {
            setShowingAddRequirement(false)
            setNewRequirement('')
            e.stopPropagation()
            e.preventDefault()
        }
    }

    return (
        <Box flex column gap="5xl" style={{ flexGrow: 1 }}>
            <Body>
                List each entity that is important to the process or workflow you want to manage
                with your app.
            </Body>
            <Box flex column overflow="auto" alignItems="flex-start" gap="s">
                {requirements.map((requirement, index) => (
                    <Tag
                        key={index}
                        showRemoveButton
                        onRemove={() => removeRequirement(requirement)}
                        color="Blue"
                    >
                        {requirement}
                    </Tag>
                ))}
                {!showingAddRequirement && (
                    <Button
                        variant="dotted"
                        border
                        size="s"
                        onClick={() => setShowingAddRequirement(true)}
                        mt="m"
                    >
                        Add a new entity
                    </Button>
                )}
                {showingAddRequirement && (
                    <Input
                        placeholder="new requirement"
                        value={newRequirement}
                        onChange={(e) => setNewRequirement(e.target.value)}
                        onBlur={() => saveNewItem()}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        mt="m"
                    />
                )}
            </Box>

            <Button
                variant="primary"
                width="full"
                size={buttonSize}
                onClick={onMoveNext}
                disabled={isGenerating || generationFailed}
            >
                {isGenerating ? 'Generating...' : 'Finish'}
            </Button>
        </Box>
    )
}
