import React from 'react'

import { useAiAppBuilder } from 'features/AiPlayground/AiAppBuilderContext'
import { AiGenerationFailed } from 'features/AiPlayground/AiGenerationFailed'
import { SimpleBundlePreview } from 'features/AiPlayground/components/SimpleBundlePreview/SimpleBundlePreview'

import { Box } from 'ui/components/Box'
import { Progress } from 'ui/components/Progress'
import { Spinner } from 'ui/components/Spinner'
import { Body } from 'ui/components/Text'

export function OnboardingAppPreview({
    installDefaultBundle,
}: {
    installDefaultBundle: () => Promise<void>
}) {
    const { bundle, generationFailed, isGenerating, progress, currentStep } = useAiAppBuilder()

    const showPreview = currentStep !== 'describe' && !generationFailed
    return (
        <Box
            flex
            column
            style={{ flexBasis: '60%' }}
            p="3xl"
            background={'gray100'}
            shrink
            rounded="m"
            width="full"
            maxWidth="full"
        >
            {generationFailed && <AiGenerationFailed onContinue={installDefaultBundle} />}
            {showPreview && (
                <>
                    <Box
                        flex
                        column
                        width="full"
                        alignSelf="stretch"
                        mb="xl"
                        style={{ visibility: isGenerating ? 'visible' : 'hidden' }}
                    >
                        <Progress
                            value={progress.progress}
                            width="full"
                            alignSelf="stretch"
                            style={{ width: '100%' }}
                            rounded="s"
                        >
                            <Box flex center gap="m">
                                <Spinner size="s" style={{ opacity: 0.5 }} />
                                <Body color="textWeakest">Generating preview:</Body>
                                <Body>{progress.status}</Body>
                            </Box>
                        </Progress>
                    </Box>
                    <Box flex column gap="l" justifyContent="center" shrink grow>
                        <Box height="full" maxHeight="600px">
                            {bundle && <SimpleBundlePreview bundle={bundle} />}
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    )
}
